/**
 * Email sender
 */

export default (() => {
  const forms = document.querySelectorAll('form.needs-validation');

  // Function to display a notification
  function showNotification(message, type) {
    const notification = document.createElement('div');
    notification.className = `position-fixed bottom-0 end-0 p-4 alert alert-dismissible fade show ${type}`;
    notification.role = 'alert';
    notification.innerHTML = `
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;
    document.body.appendChild(notification);

    // Remove the notification after a certain time
    setTimeout(() => {
        notification.remove();
    }, 10000); // Remove after 5 seconds
  }

  forms.forEach(form => {    
    form.addEventListener('submit', (event) => {
      event.preventDefault(); // Prevents the default form behavior

      if (form.checkValidity() === false) {
        event.stopPropagation();
        form.classList.add('was-validated');
        return;
      }

      // Create FormData from the form
      const formData = new FormData(form);

      // Get the URL of the PHP script from the form's action attribute
      const actionUrl = form.getAttribute('action');

      // Send the request to the backend
      fetch(actionUrl, {
        method: 'POST',
        body: formData,
      })
      .then(response => response.text())
      .then(result => {
        console.log(result);
        showNotification('Die E-Mail wurde erfolgreich versendet!', 'alert-success');
        form.reset(); // Reset the form fields
        form.classList.remove('was-validated'); // Remove validation class
      })
      .catch(error => {
        console.error('Error:', error);
        showNotification('Beim Senden der E-Mail ist ein Fehler aufgetreten.', 'alert-danger');
      });

      form.classList.add('was-validated'); // Adds the validation class
    }, false);
  });
})();
