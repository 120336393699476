/* eslint-disable no-undef */
/**
 * PhotoSwipe image gallery
 * @requires https://github.com/dimsemenov/photoswipe
 */

export default (() => {
  const galleryElements = document.querySelectorAll('.pswp-gallery');

  if (galleryElements.length === 0) {
    console.error('No galleries found.');
    return;
  }

  galleryElements.forEach((gallery) => {
    const items = [];
    const childElements = gallery.children;

    // Loop through child elements to collect items
    for (let i = 0; i < childElements.length; i++) {
      const child = childElements[i];
      if (child.nodeType === 1) { // Element node
        const link = child.querySelector('a');
        const item = {
          src: link.getAttribute('href'),
          w: parseInt(link.getAttribute('data-width')),
          h: parseInt(link.getAttribute('data-height'))
        };

        // Check if the link is a video
        if (link.getAttribute('data-type') === 'video') {
          const videoSrc = link.getAttribute('data-video-src');
          if (videoSrc.includes('youtube.com') || videoSrc.includes('youtu.be')) {
            // If it's a YouTube video, extract the video ID
            const videoId = getYouTubeVideoId(videoSrc);
            const iframe = `<iframe width="100%" height="100%" src="https://www.youtube.com/embed/${videoId}?autoplay=1" frameborder="0" allowfullscreen></iframe>`;
            item.html = iframe;
          } else {
            // If it's not a YouTube video, assume it's a direct video URL
            const iframe = `<iframe width="100%" height="100%" src="${videoSrc}" frameborder="0" allowfullscreen></iframe>`;
            item.html = iframe;
          }
        }

        items.push(item);
      }
    }

    // Initialize PhotoSwipe
    gallery.addEventListener('click', function(event) {
      event.preventDefault();
      const options = {
        index: parseInt(event.target.getAttribute('data-index')),
        bgOpacity: 0.85,
        showHideOpacity: true,
        getThumbBoundsFn: function(index) {
          // Find thumbnail element
          const thumbnail = gallery.children[index].querySelector('img');
          // Get page scroll Y
          const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          // Get rect
          const rect = thumbnail.getBoundingClientRect();
          // Return data object
          return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
        }
      };
      const lightbox = new PhotoSwipe(document.querySelector('.pswp'), PhotoSwipeUI_Default, items, options);
      lightbox.init();
    });
  });
})();

// Function to extract YouTube video ID from URL
function getYouTubeVideoId(url) {
  let videoId = '';
  if (url.includes('youtube.com')) {
    const match = url.match(/[?&]v=([^&]+)/);
    videoId = match && match[1] ? match[1] : '';
  } else if (url.includes('youtu.be')) {
    const match = url.match(/youtu.be\/([^&]+)/);
    videoId = match && match[1] ? match[1] : '';
  }
  return videoId;
}
/* eslint-enable no-undef */
