/* global FilePond */

/**
 * FilePond file upload
 * @requires https://github.com/pqina/filepond
 */
export default (() => {
  // Ensure FilePond is loaded globally
  if (typeof FilePond !== 'undefined') {
    // Initialize FilePond for all elements with the class 'filepond'
    const elements = document.querySelectorAll('.filepond');

    elements.forEach(element => {
      FilePond.create(element, {
        allowMultiple: true,
        maxFiles: 10,
        acceptedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
        credits: false,
        labelIdle: 'Dateien ablegen oder <span class="filepond--label-action"> auswählen </span>',
      });
    });
  } else {
    console.error('FilePond is not defined. Make sure it is included in your HTML.');
  }
})();
