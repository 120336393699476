/**
 * Animate scroll to top button in/off view
 */

export default (() => {
  const element = document.querySelector('.btn-scroll-top');
  const path = document.querySelector('.btn-scroll-top path');
  const length = path.getTotalLength();
  const scrollOffset = 50;

  if (element == null) return

  let offsetFromTop = parseInt(scrollOffset, 10)

    path.style.transition = path.style.WebkitTransition = 'none';
    path.style.strokeDasharray = `${length} ${length}`;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

    window.addEventListener('scroll', (e) => {
      const scroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = length - (scroll * length / height);
      path.style.strokeDashoffset = progress;

      if (e.currentTarget.pageYOffset > offsetFromTop) {
        element.classList.add('show')
      } else {
        element.classList.remove('show')
      }
    })

    element.addEventListener('click', function (e) {
      e.preventDefault();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    });
})()
