/**
 * Cookie consent notification
 */

export default (() => {
    // Check if cookie consent is already given
    if (!localStorage.getItem('cookieConsent')) {
      // Create cookie consent element
      const consentElement = document.createElement('div');
      consentElement.className = 'cookie-consent position-fixed bottom-0 end-0 bg-light shadow-lg me-4 mb-3 col-9 col-lg-3';
      consentElement.style.zIndex = '1000';
      consentElement.innerHTML = `
        <div class="p-4">
          <div class="mb-2">
            <p class="mb-0">Wir verwenden Cookies, um Ihnen die beste Erfahrung auf unserer Website zu bieten. Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. <a href="datenschutzrichtlinie-und-cookies.html" target="_blank">Mehr erfahren</a></p>
          </div>
          <button type="button" class="btn btn-primary" id="acceptCookies">Akzeptieren</button>
        </div>
      `;
  
      // Append consent element to body
      document.body.appendChild(consentElement);
  
      // Handle accept button click
      const acceptButton = document.getElementById('acceptCookies');
      acceptButton.addEventListener('click', () => {
        localStorage.setItem('cookieConsent', 'true');
        consentElement.remove();
      });
    }
  })();
  